var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('spinner-layout',{attrs:{"loading":_vm.loading}},[_c('h5',{staticClass:"mt-heading mt-heading--s mt-heading--center mt-heading--underline"},[_vm._v(" "+_vm._s(_vm.listProducts.packName)+" ")]),(_vm.listProducts.items.length > 0)?_c('div',_vm._l((_vm.listProducts.items),function(product,index){return _c('div',{key:index,staticClass:"container-detail mu-mb-100",attrs:{"id":("product-container-" + (product.sku))}},[_c('router-link',{attrs:{"to":{
          name: 'group-product-detail',
          params: {
            group: _vm.group,
            sku: product.sku,
            storeCode: _vm.storeSelected.code
          }
        }}},[_c('div',{staticClass:"ml-container"},[_c('div',{staticClass:"ml-flexy"},[_c('div',{staticClass:"ml-flexy__col--1of3"},[_c('div',{staticClass:"container-detail__item"},[_c('img',{attrs:{"src":product.images[0]}})])]),_c('div',{staticClass:"ml-flexy__col--fill mu-ml-100"},[_c('div',{staticClass:"container-detail__item"},[_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(product.productName)+" ")]),_c('p',{staticClass:"reference mu-mt-025"},[_vm._v(" "+_vm._s(("(Ref. " + (product.sku) + ")"))+" ")])])])])])])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }