<template>
  <spinner-layout :loading="loading">
    <h5 class="mt-heading mt-heading--s mt-heading--center mt-heading--underline">
      {{ listProducts.packName }}
    </h5>
    <div v-if="listProducts.items.length > 0">
      <div
        v-for="(product, index) in listProducts.items"
        :id="`product-container-${product.sku}`"
        :key="index"
        class="container-detail mu-mb-100"
      >
        <router-link
          :to="{
            name: 'group-product-detail',
            params: {
              group,
              sku: product.sku,
              storeCode: storeSelected.code
            }
          }"
        >
          <div class="ml-container">
            <div class="ml-flexy">
              <div class="ml-flexy__col--1of3">
                <div class="container-detail__item">
                  <img :src="product.images[0]" />
                </div>
              </div>

              <div class="ml-flexy__col--fill mu-ml-100">
                <div class="container-detail__item">
                  <p class="name">
                    {{ product.productName }}
                  </p>

                  <p class="reference mu-mt-025">
                    {{ `(Ref. ${product.sku})` }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </spinner-layout>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ListProducts',
  data() {
    return {
      group: this.$route.params.group,
      storeCode: this.$route.params.storeCode,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getDataProducts', 'getDataStores']),
    listProducts() {
      return this.getDataProducts('products');
    },
    storeSelected() {
      return this.getDataStores('selected');
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
@import 'settings-tools/_all-settings';
@include import-font-families();
@import 'typography/_t.headings';

.container-detail__item {
  text-align: left !important;

  img {
    max-width: 50px !important;
  }
}
.container-detail .name {
  font-size: $size-font-05;
}
</style>
